<template>
  <div>
    <el-drawer class="appealRecordDetail-drawer" title="超时预警详情" :visible.sync="drawer" :append-to-body="true" direction="rtl" :before-close="handleClose">
      <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="drawer-container">
        <div class="drawer-left">
          <div>
              <div class="detail-title">
                <div>
                  <span>{{ tableRow.code || "--" }}</span>
                  <div v-if="tableRow.dealStatus == 'deal'" class="common-tableStatus-blue" style="margin-right: 16px;">已处理</div>
                  <div v-if="tableRow.dealStatus == 'unDeal'" class="common-tableStatus-red" style="margin-right: 16px;">未处理</div>
                  <div class="common-tableStatus-blue" v-if="tableRow.remarkType == 'full'">咨询量爆量</div>
                  <div class="common-tableStatus-blue" v-if="tableRow.remarkType == 'personal'">个人原因</div>
                  <div class="common-tableStatus-blue" v-if="tableRow.remarkType == 'unusual'">软件原因</div>
                  <!-- <div v-else class="common-tableStatus-blue">已查看</div> -->
                </div>
                <div class="handle-btn" @click="() => { handleShow() }">超时处理</div>
              </div>
              <div class="detail-content">
                  <div class="detail-item">超时类型：<span>{{tableRow.warningType == 'first' ? '首响超时' : '平响超时'}}</span></div>
                  <div class="detail-item">超时时长：<span>{{tableRow.responseTime || tableRow.responseTime == 0 ? tableRow.responseTime : '0'}}s</span></div>
                  <div class="detail-item">聊天时间：<span>{{tableRow.recordTime || '--'}}</span></div>
                  <div class="detail-item">店铺名称：<span>{{tableRow.shopName || '--'}}</span></div>
                  <div class="detail-item">子账号名称：<span>{{tableRow.subaccountName || '--'}}</span></div>
                  <div class="detail-item">负责客服：<span>{{tableRow.adminNames || '--'}}</span></div>
                  <div class="detail-item">顾客账号：<span>{{tableRow.customerNick || '--'}}</span></div>
                  <div class="detail-item">客服所属公司：<span>{{tableRow.companyName || '--'}}</span></div>
                  <div class="detail-item">备注最后填写人：<span>{{tableRow.remarkName || '--'}}</span></div>
              </div>
              <div class="detail-remark"><span>备注：</span> <span>{{tableRow.remark || '--'}}</span></div>
          </div>
          <div class="upload-container">
            
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div v-for="(item, index) in fileList" :key="index" class="fileItem">
                <div class="fileItem-name">
                  <common-picture :fileUrl="item.url" :fileType="Config.fileType.imgType.includes(item.url.split('.')[item.url.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.name"></common-picture>
                  <!-- {{item.name}} -->
                  </div>
                <div class="fileItem-btn-Container">
                  <!-- <div class="fileItemBtn" @click="() => {download(item.url)}">查看</div> -->
                  <div class="fileItemBtn" @click="() => {download(item.url)}">下载</div>
                  <el-popconfirm
                    placement="top"
                    title="确定删除吗？"
                    @confirm="deleteFile(item)"
                  >
                    <!-- <el-button type="text" size="small" slot="reference">删除</el-button> -->
                  </el-popconfirm>
                  <!-- <div class="fileItemBtn" @click="() => {deleteFile(item)}">删除</div> -->
                </div>
              </div>
            </div>
            <div v-if="!fileList || !fileList.length" class="fileList-container">
              <div class="fileItem">
                暂无附件
              </div>
            </div>
          </div>
          <el-tabs class="leftTabs" v-model="activeNameLeft">
            <el-tab-pane label="聊天记录" name="ChatRecord">
              <div v-if="chatData && chatData.length">
                <div class="chatItem" v-for="(item, index) in chatData" :key="index">
                  <div class="chatItem-title">
                    <div>{{item.from_id}}</div>
                    <div>{{item.msg_time}}</div>
                  </div>
                  <div class="chatItem-content" :style="item.from_id != item.customer_nick ? 'color: #f00' : ''" v-if="item.msg_type === 0">{{item.msg_content}}</div>
                  <a :href="item.msg_content" target="_blank" v-else-if="item.msg_type === 1">点击跳转</a>
                  <span class="chatItem-content" v-else-if="item.msg_type === 2" >【{{ item.msg_content }}】--苹果手机表情+文字（不兼容）</span>
                  <span class="chatItem-content" v-else-if="item.msg_type === 3" >【表情图暂不支持查看】</span>
                  <a :href="item.msg_content" target="_blank" v-else-if="item.msg_type === 5" >淘宝链接--点击查看
                  </a>
                  <a target="_blank" :href="item.msg_content" v-else-if="item.msg_type === 6" >【视频资源——点击查看】</a>
                  <!-- <img class="chartItem-img" :src="item.msg_content" v-if="item.msg_type === 7" @click="showImg(item.msg_content)" /> -->
                  <el-image class="chartItem-img" :src="item.msg_content" v-if="item.msg_type === 7" :preview-src-list="[item.msg_content]"></el-image>
                  <span class="chatItem-content" v-else-if="item.msg_type === 9">【{{ item.msg_content }}】</span>
                  <span class="chatItem-content" v-else-if="item.msg_type === 10">[{{ item.msg_content }}] </span>
                </div>
              </div>
              <div v-else>暂无记录</div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName">
            <el-tab-pane label="工作记录" name="appeal">
              <div>
                <el-input :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent" placeholder="工作小计"></el-input>
                <div>
                  <div class="fileItem" v-for="(item, index) in filesList" :key="index">
                    <div><common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture></div>
                    <div class="common-blue-btn" @click="() => deleteFile(item.fileId)">删除</div>
                  </div>
                </div>
                <div class="appeal-commit">
                  <div>
                    <div class="addFileBtn">
                      <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg" />添加附件
                      <input class="addFileInput" :multiple="false" @input="chooseFile" type="file" />
                    </div>
                    <div style="font-size: 12px; color: #ccc"> 上传文件及图片，大小不超过50M </div>
                  </div>
                  <el-button :loading="btnLoading" @click="() => {updateEarlyWarn()}" class="common-screen-btn" type="primary" >发布</el-button>
                </div>
              </div>
              <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <el-dialog  v-loading="loading" element-loading-text="数据较多，拼命加载中..." v-dialogDrag title="超时处理" :append-to-body="true" width="530px" :visible.sync="handleVisible" :close-on-click-modal="false">
      <el-form :model="ruleFormHandle" :rules="ruleHandleRules" ref="ruleFormHandle" label-width="110px" class="demo-ruleForm">
        <el-form-item label="原因类型：" prop="remarkType">
          <el-select style="width: 100%" v-model="ruleFormHandle.remarkType" placeholder="请选择原因类型" filterable>
            <el-option label="咨询量爆量" value="full"></el-option>
            <el-option label="个人原因" value="personal"></el-option>
            <el-option label="软件原因" value="unusual"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="超时说明：" prop="remark">
          <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="ruleFormHandle.remark"></el-input>
        </el-form-item>
        <el-form-item label="">
          <div class="orderFile-container">
            <div class="orderFileBtn"> 上传附件 <input class="orderFile-input" type="file" @input="(e) => {handleFile(e)}"></div>
            上传文件及图片，大小不超过50MB
          </div>
          <div class="fileList-container">
            <div v-for="(item, index) in handleFileList" :key="index" class="fileList-item">
              <div class="item-name">
                <common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture>
              </div>
              <div @click="() => {deleteFlie(index)}" class="deleteBtn">删除</div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="handleVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => { handleOk() }">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { Config, tableColumn, DateTransform } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
import { upload } from "../../../service/upload.js"
import { chatHistory, updateEarlyWarn } from "../../../service/service.js"
import CommonWorkLog from "../../../components/common/commonWorkLog.vue"
import { workLog }  from "../../../service/common.js"
export default {
  props: ["drawer", "handleClose"],
  components: { CommonPicture, CommonWorkLog },
  data() {
    return {
      DateTransform,
      loading: false,
      Config,
      tableColumn,
      fileList:[],
      activeName: "appeal", // tabs
      activeNameLeft: "ChatRecord", // 左侧tabs
      recordContent: '', // 工作小计
      filesList: [],  // 工作小计文件上传
      tableRow: {}, // 列表一行信息
      chatData: [], // 聊天数据
      followRecordList: [], // 工作日志
      handleVisible: false,
      ruleFormHandle: {
        remarkType: '',
        remark: ''
      },
      ruleHandleRules: {
        remarkType: [{ required: true, message: "不能为空", trigger: "change" }],
        remark: [{ required: true, message: "不能为空", trigger: "change" }],
      },
      handleFileList: []
    };
  },
  created() {},
  computed: {
    ...mapState(["btnLoading"])
  },
  methods: {
    async handleOk() {  // 确认处理异常
      this.$refs['ruleFormHandle'].validate(async (valid) => {
        if (valid) { // 发送请求
          let ruleFormHandle = {...this.ruleFormHandle}
          let handleFileList = [...this.handleFileList];
          let urlArr = []
          let nameArr = []
          handleFileList.map((item) => {
            urlArr.push(item.fileUrl)
            nameArr.push(item.fileName)
          })
          ruleFormHandle.id = this.tableRow.id
          ruleFormHandle.fileUrl = urlArr.join(',')
          ruleFormHandle.fileName = nameArr.join(',')
          this.loading = true
          let res = await updateEarlyWarn(ruleFormHandle)
          this.loading = false
          if (res.code == 200) {
            this.$message.success("处理成功")
            this.$emit('loadDetailInfo', this.tableRow.id)
            this.handleVisible = false
            this.workLog(this.tableRow.id)
          }
        } else {
          return false
        }
      });
    },
    handleShow () { // 显示处理异常
      this.ruleFormHandle = {
        remarkType: '',
        remark: ''
      }
      this.handleFileList = []
      if(this.$refs["ruleFormHandle"]) {
        this.$refs["ruleFormHandle"].resetFields();
      }
      this.handleVisible = true
    },
    deleteFlie (i) {  //  处理异常-删除文件
      let handleFileList = [...this.handleFileList]
      handleFileList = handleFileList.filter((item,index)=>{
        return i != index
      })
      this.handleFileList = handleFileList
    },
    async handleFile (e) { // 处理异常-上传附件
      let handleFileList = [...this.handleFileList]
      let file = e.target.files[0];
      let fileName = file.name;
      let fileType
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
        fileType = 'img'
      } else {
        fileType = 'file'
      }
      this.loading = true
      let resData = (await upload({ file: file })).data;
      this.loading = false
      handleFileList.push({fileName: fileName, fileUrl: resData.filename, fileType: fileType})
      this.handleFileList = handleFileList
      e.target.value = ''
    },
    tableParams (row) { // 获取列表的值
      this.tableRow = row
      let fileUrls = row.fileUrl ? row.fileUrl.split(',') : []
      let fileNames = row.fileName ? row.fileName.split(',') : []
      let fileList = []
      fileNames.map((item, index) => {
        fileList.push({url: fileUrls[index], name: item})
      })
      this.fileList = fileList
      this.chatHistory()
      this.workLog(row.id)
      if (row.id && row.readFlag == '0') {
        this.updateEarlyWarnRead(row.id)
      }
      
    },
    async workLog(id) { // 工作日志
      let resData = (await workLog({id: id, type: 4})).data
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = [...resData]
    },
    async chatHistory() {// 聊天记录
      let params = { ids: this.tableRow.chatHistoryIds }
      let { data } = await chatHistory(params)
      this.chatData = data
    },
    async updateEarlyWarnRead (id) { // 标记已读
      await updateEarlyWarn({ id: id, readFlag: 1})
    },
    async updateEarlyWarn() { // 质检申诉-工作小计
      if (!this.recordContent) {
        return this.$message.error("工作小计不能为空")
      }
      let filesList = []
      let params = {}
      
      filesList = [...this.filesList]
      let urlArr = []
      let nameArr = []
      filesList.map((item) => {
        urlArr.push(item.fileUrl)
        nameArr.push(item.fileName)
      })
      params = {
        id: this.tableRow.id,
        subtotal: this.recordContent,
        subtotalFile: urlArr.join(','),
        subtotalFileName: nameArr.join(',')
      }
      
      this.loading = true
      let res = await updateEarlyWarn(params)
      this.loading = false
      if (res.code == 200) {
          this.$message.success("发布成功")
          this.filesList = []
          this.recordContent = ''
        this.workLog(this.tableRow.id)
      }
    },
    deleteFile (id) { // 删除申诉文件
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    async chooseFile(e) { // 发起申诉选择文件
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading = true
      let resData = (await upload({ file: file })).data;
      this.loading = false
      let fileItem = {
        fileType: "",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
        fileItem.fileType = "img";
      }
      this.filesList.push(fileItem);
    },
  },
};
</script>
<style lang="less" scoped>
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
    font-size: 12px;
    color: #333;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    }
    .deleteBtn {
    color: #1890ff;
    cursor: pointer;
    }
  }
}
.orderFile-container {
  .orderFileBtn {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border:  1px solid rgba(220, 223, 230, 100);
    color: #666;
    background: #fff;
    text-align: center;
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    .orderFile-input {
      width: 100px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
      font-size: 0;
    }
  } 
}
/deep/.el-tabs__content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
/deep/.el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.fileItem {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.appealRecordDetail-drawer {
  text-align: left;
  color: #101010;
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .upload-container {
        margin-top: 48px;
        .fileList-container {
          border: 1px solid #EBEEF5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #EBEEF5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890FF;
            color: #1890FF;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
    .drawer-left {
      padding: 0 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .detail-title {
        line-height: 78px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .handle-btn {
          width: 100px;
          height: 40px;
          line-height: 40px;
          border-radius: 4px;
          color: #fff;
          background: #1890FF;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          cursor: pointer;
        }
        span:nth-of-type(1) {
          font-size: 18px;
          margin-right: 16px;
        }
      }
      .detail-content {
        width: 900px;
      }
      .detail-item {
        width: 32%;
        display: inline-block;
        margin-bottom: 16px;
        color: #999;
        span {
          color: #666;
        }
      }
      .detail-remark {
        display: flex;
        width: 80%;
        span {
          display: inline-block;
          &:nth-of-type(1) {
            color: #999;
            white-space: nowrap;
          }
          &:nth-of-type(2) {
            color: #666;
          }
        }
      }
      .leftTabs {
        margin-top: 36px;
        .chatItem {
          color: #101010;
          font-size: 14px;
          margin-top: 8px;
          .chatItem-title {
            display: flex;
            justify-content: space-between;
            line-height: 38px;
          }
          .chatItem-content {
            line-height: 38px;
            background: #F7F8FB;
            padding-left: 10px;
          }
          .chartItem-img {
            width: 80px;
          }
        }
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .appeal-commit {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center;
        padding-bottom: 10px;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
      
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
      width: 1656px !important;
  }
  /deep/ .el-drawer__body {
      padding: 0;
  }
  /deep/ .el-tabs__content {
      padding: 0 8px 0 16px;
  }
  /deep/ .el-image-viewer__close {
      background: #606266;
      border-radius: 50%;
      .el-icon-circle-close:before {
          font-size: 20px;
          color: #fff;
          content: "\e6db";
      }
  }
}

</style>
